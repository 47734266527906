import React, { useState } from 'react';
import styled from 'styled-components';
import Input from '../atoms/Input';
import NormalInputBox from '../atoms/NormalInputBox';
import useForm from 'react-hook-form';
import MessageBar from '../molecules/MessageBar';
import Button from '../atoms/Button';
import { createUser  } from '../../lib/api_user';
import { useHistory } from 'react-router';
const Container = styled.div`
  position:relative;
  margin-left:47px;
  margin-right:47px;
  margin-bottom:30px;
  max-width:1304px;
`;

const Box = styled.div`
  margin-top:33px;
  max-width:540px;
  justify-content:flex-end;
  display:flex;
  & div{
    margin-bottom:0px;
  }
  button{
    margin:10px;
  }
`;
const Title = styled.div`
  font-family: Rawline;
  font-size: 20px;
  font-weight: 500;
  color: hsl(208, 8%, 39%);
  margin-bottom:16px;
`;
const SubTitle = styled.div`
  font-family: Rawline;
  font-size: 14px;
  font-weight: 400;
  color: hsl(207, 5%, 57%);
  margin-bottom:57px;
  font-style: italic;
`;

const CreateUser: React.FC = () => {
  const history = useHistory()
  const { register, watch, errors, formState , getValues} = useForm({mode: 'onChange'});
  const [message,setMessage] = useState({type:'',msg:''})
  const [globalLoading, setGlobalLoading] = useState(false);
  const isEmail = (email:string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const handleSave = async() => {
    const {username, confirmPassword, password, email} = getValues();
    if (!isEmail(email)) {
      setMessage({type:'error', msg:'Invalid Email'});
      return true
    }
    if (!password) {
      setMessage({type:'error', msg:'Password is required.'});
      return true
    } else {
      if (!confirmPassword) {
        setMessage({type:'error', msg:'Password Confirm is required.'});
        return true
    } else {
        if (password !== confirmPassword) {
          setMessage({type:'error', msg:'Password not match.'});
          return true
        }
      }
    }
    setGlobalLoading(true);
    try {
      await createUser({username, password, email});
      setMessage({type:'success',msg:'created user successfully'})
      setTimeout(()=>{history.goBack()},1000)
    } catch(err) {
      setMessage({type:'error',msg:err.message || err.reason})
    }
    setGlobalLoading(false);
  };

    return <Container>
        <Title>Create User Account</Title>
        <SubTitle>Put your client information.</SubTitle>
        <NormalInputBox label={'Username'} name='username' form={{formState,errors,watch}}>
          <Input placeholder={''} name='username' register={register({ required: true })} ></Input>
        </NormalInputBox>
        <NormalInputBox label={'Email'} name='email' form={{formState,errors,watch}}>
          <Input placeholder={''} name='email' register={register({ required: true })} ></Input>
        </NormalInputBox>
        <NormalInputBox label={'Password'} name='password' form={{formState,errors,watch}}>
          <Input placeholder={''} name='password' type="password" register={register({ required: true ,minLength:{value:9,message:'Password length should be more than 9 characters'}})} ></Input>
        </NormalInputBox>
        <NormalInputBox label={'Confirm Password'} name='confirmPassword' form={{formState,errors,watch}}>
          <Input placeholder={''} name='confirmPassword'  type="password" register={register({ required: true})} ></Input>
        </NormalInputBox>
        {message && <Box>
          {<MessageBar position={'relative'} width={'100%'} type={message.type ==='error'?'danger':'success'} message={message.msg} onClose={() => {setMessage({msg:'',type:''}); }}></MessageBar>}
        </Box>}
        {formState.isValid !== null && !globalLoading && <Box>
          <Button onClick={()=>history.goBack()} >Back</Button>
          <Button onClick={handleSave} disabled={!formState.dirty || !formState.isValid || (Object.keys(errors).length >= 1)}>Save</Button>
        </Box>}
    </Container>;
};

export default CreateUser;
