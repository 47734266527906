import React, { Fragment } from 'react';
import styled from 'styled-components';
import { RowCss, Column } from '../tools/common/index';
import Button from '../atoms/Button';

const Container = styled.div`
  padding:10px;
  ${RowCss}
  min-height: 50px;
  border-radius: 5px;
  box-shadow: 0 0 6px 0 hsla(207, 68%, 35%, 0.21);
  border: solid 1px hsl(0, 3%, 89%);
  background-color: hsl(0, 0%, 100%);
  position: absolute;
  right: 47px;
  top: 15px;
  ${Column}{
    margin-right:53px;
  }
  & button:last-child {
    margin-left: 10px;
  }
`;

const Detail = styled.div`
  font-family: Rawline;
  font-size: 14px;
  line-height: 1.5;
  color: hsl(207, 5%, 57%);
`;

const Title = styled(Detail)`
  font-weight: 700;
`;

const PopupDialogContainer  = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index:100;
`;

export const PopupBg = styled.div`
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: hsla(0, 0%, 100%,0.65);
z-index:99;
`;



export interface DialogPopupProps {
  title?: string;
  detail?: string;
  leftBtn?:() => void;
  rightBtn?:() => void;
  leftTxt:string;
  rightTxt:string;
  show:boolean;
}

const DialogPopup: React.FC<DialogPopupProps> = ({title, children, rightBtn, leftBtn, detail, leftTxt, rightTxt, show} ) => {
    return show ?<Fragment>
      <PopupBg></PopupBg>
    <PopupDialogContainer>
      <Container>
          <Column>
            <Title>{title}</Title>
            <Detail>{detail}</Detail>
          </Column>
          <Button colorType={'danger'} onClick={() => {leftBtn && leftBtn(); }}>{leftTxt}</Button>
          <Button colorType={'secondary'} onClick={ () => {rightBtn && rightBtn(); }}>{rightTxt}</Button>
      </Container>
    </PopupDialogContainer>
    </Fragment>:null;
};

export default DialogPopup;
