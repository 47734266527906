import React, { useState, ChangeEvent }  from 'react';
import styled from 'styled-components';
import { getSubscriptions, SaveSubscriptionResponse } from '../../lib/api_subscription';
import Table from '../molecules/Table';
import DialogPopup from '../molecules/DialogPopup';
import Button from '../atoms/Button';
import moment from 'moment';
import Input from '../atoms/Input';
import { RowCss } from '../tools/common/index';
import { useHistory } from 'react-router';

const Container = styled.div`
  position:relative;
  margin-left:47px;
  margin-right:47px;
  margin-bottom:30px;
  max-width:1304px;
  table tr{
    height:56px;
  }
`;
const Box = styled.div`
  font-family: Rawline;
  font-size: 14px;
  font-weight: 400;
  color: hsl(208, 8%, 39%);
`;
const Title = styled.div`
  font-family: Rawline;
  font-size: 20px;
  font-weight: 500;
  color: hsl(208, 8%, 39%);
  margin-bottom:16px;
`;
const SubTitle = styled.div`
  font-family: Rawline;
  font-size: 14px;
  font-weight: 400;
  color: hsl(207, 5%, 57%);
  margin-bottom:10px;
  font-style: italic;
`;
const RightBox = styled.div`
  position:relative;
  button{
    position:absolute;
    right:0px;
    z-index:2;
  }
`
const SearchBox = styled.div`
  width:340px;
  ${RowCss}
  margin-bottom:20px;
  input{
    border:1px solid #d3d3d3;
    height:44px;
    padding-left:10px;
    margin-right:10px;
  }
`

const Subscription: React.FC = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [selected, setSelected] = useState();
    const handleClickEdit = (subscription:SaveSubscriptionResponse)=>{
        setShowDialog(true)
        setSelected(subscription)
    }
    const [search, setSearch] = useState('');
    const [subscriptionList, setSubscriptionList] = useState<SaveSubscriptionResponse[]>([]);
    const handleGetSubscriptions = async()=>{
      try{
        const subscriptions = await getSubscriptions(search)
        subscriptions && setSubscriptionList(subscriptions)
      } catch(err) {
        console.log(err);
        return false;
      }
    }
    const handleChange = (e:ChangeEvent<HTMLInputElement>)=>{
      setSearch(e.target.value)
    }
    const history = useHistory();

    return <Container>
        <SearchBox>
          <Input placeholder={'Customer ID'} value={search} onChange={handleChange}></Input>
          <Button onClick={()=>{handleGetSubscriptions()}}>Search</Button>
        </SearchBox>
        <RightBox><Button onClick={()=>{history.push(`/edit-subscription-user`,selected)}}>Create New Record</Button></RightBox>
        {<DialogPopup show={showDialog} leftBtn={()=> { history.push('/edit-subscription-user',selected)}} rightBtn={() => {setShowDialog(false); }} leftTxt='Edit' rightTxt='Cancel' title='Are you sure you want to edit?' detail='Doing this will force update the record.'></DialogPopup>}
        <Title>List Subscriptions</Title>
        <SubTitle>You can edit and delete.</SubTitle>
        <Table ths={[ {component:() => <div>Serial ID</div>, width:'', align:'left' as 'left'},
                      {component:() => <div>Detail</div>, width:'', align:'left' as 'left'},
                      {component:() => <div>Start</div>, width:'', align:'left' as 'left'},
                      {component:() => <div>End</div>, width:'', align:'left' as 'left'},
                      {component:() => <div></div>, width:'40px', align:'left' as 'left'},]}>
          {subscriptionList && subscriptionList.map((subscription,i)=>
            {
              const {startDate, type, price,serial,endDate} = subscription
              const passed = !!endDate && moment().isAfter(moment(endDate))
              return <tr key={i}>
              <td><Box>{serial}</Box></td>
              <td><Box>Price: {price} yen | Subscription: {type}</Box></td>
              <td><Box>{startDate}</Box></td>
              <td><Box>{endDate}</Box></td>
              <td><Button colorType={passed?'secondary':'primary'} onClick={()=>passed?null:handleClickEdit(subscription)}>EDIT</Button></td>
            </tr>})}
        </Table>
    </Container>;
};

export default Subscription;

