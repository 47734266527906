import React from 'react';
import Amplify from 'aws-amplify';
import styled from 'styled-components';
import { withAuthenticator } from 'aws-amplify-react'; // or 'aws-amplify-react-native';
import awsConfig from './service/amplify';
import AddEditSubscription from './component/pages/AddEditSubscription';
import Subscription from './component/pages/Subscription'
import CreateUser from './component/pages/CreateUser';
import LogoutButton from './component/molecules/LogoutBtn';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

Amplify.configure(awsConfig);

const MenuRow = styled.ul`
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    list-style:none;
    margin-right:40px;
    li{
      padding:10px 20px;
      border-radius: 30px;
      box-shadow: 0 3px 7px 0 hsla(207,68%,35%,0.21);
      background: linear-gradient(108deg,hsl(200,78%,70%),hsl(207,80%,64%) 100%);
      margin-left:20px;
      font-weight:700;
      font-size:16px;
      a{
        text-decoration:none;
        color:#fff;
      }
    }
`


const App: React.FC = () => {
  return (
    <Router>
      <div>
        <nav>
          <MenuRow>
            <li>
              <Link to="/create-user">Create New User Account</Link>
            </li>
            <li>
              <Link to="/subscription-list">Subscription List</Link>
            </li>
              <LogoutButton></LogoutButton>
          </MenuRow>
        </nav>
        <Switch>
          <Route path="/subscription-list" component={Subscription}></Route>
          <Route path="/create-user" component={CreateUser}></Route>
          <Route path="/edit-subscription-user" component={AddEditSubscription}></Route>
          <Route path="/" component={Subscription}></Route>
        </Switch>
      </div>
    </Router>
  );
}

export default withAuthenticator(App, false);