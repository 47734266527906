import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import Input from '../atoms/Input';
import NormalInputBox from '../atoms/NormalInputBox';
import useForm from 'react-hook-form';
import MessageBar from '../molecules/MessageBar';
import Button from '../atoms/Button';
import { saveSubscription } from '../../lib/api_subscription';
import moment from 'moment'
import { useHistory, useLocation } from 'react-router';
const Container = styled.div`
  position:relative;
  margin-left:47px;
  margin-right:47px;
  margin-bottom:30px;
  max-width:1304px;
`;

const Box = styled.div`
  margin-top:33px;
  max-width:540px;
  justify-content:flex-end;
  display:flex;
  & div{
    margin-bottom:0px;
  }
  button{
    margin:10px;
  }
`;
const Title = styled.div`
  font-family: Rawline;
  font-size: 20px;
  font-weight: 500;
  color: hsl(208, 8%, 39%);
  margin-bottom:16px;
`;
const SubTitle = styled.div`
  font-family: Rawline;
  font-size: 14px;
  font-weight: 400;
  color: hsl(207, 5%, 57%);
  margin-bottom:57px;
  font-style: italic;
`;

const AddEditSubscription: React.FC = () => {
  const history = useHistory()
  const { state } = useLocation()
  const {serial='',type='',startDate='',endDate='',price='',description='',createdAt = '', customerID=''} = state || {}
  const { register, watch, errors, formState , getValues} = useForm({mode: 'onBlur',defaultValues:{serial,type,startDate,endDate,price,description,customerID}});
  const [message,setMessage] = useState({type:'',msg:''})
  const [globalLoading, setGlobalLoading] = useState(false);
  const handleSave = useCallback(async(getValues:any) => {
    setGlobalLoading(true);
    try {
      setGlobalLoading(false);
      await saveSubscription({...getValues(),createdAt});
      setMessage({type:'success',msg:'Saved Success'})
      setTimeout(()=>{history.goBack()},2000)
      return true;
    } catch(err) {
        setGlobalLoading(false);
        setMessage({type:'error',msg:err.message || err.reason})
      return false;
    }
  }, [createdAt, history]);

    return <Container>
        <Title>Save Subscription</Title>
        <SubTitle>Fill in the Subscription information.</SubTitle>
        <NormalInputBox label={'Customer ID'} name='customerID' form={{formState,errors,watch}}>
          <Input placeholder={'aw3rf13'} name='customerID' register={register({ required: true })} ></Input>
        </NormalInputBox>
        <NormalInputBox label={'Serial'} name='serial' form={{formState,errors,watch}}>
          <Input placeholder={'AWCX2212f1g'} name='serial' register={register({ required: true })} ></Input>
        </NormalInputBox>
        <NormalInputBox label={'Subscription Type'} name='type' form={{formState,errors,watch}}>
          <Input placeholder={'Ex: LTE SIM Card'} name='type' type="string" register={register({ required: true })} ></Input>
        </NormalInputBox>
        <NormalInputBox label={'Description'} name="description" form={{formState,errors,watch}}>
          <Input placeholder={'#'} type={'description'} name="description" register={register({})} ></Input>
        </NormalInputBox>
        <NormalInputBox label={'Price'} name='price' form={{formState,errors,watch}}>
          <Input placeholder={'10'} name='price'  type="number" register={register({ required: true})} ></Input>
        </NormalInputBox>
        <NormalInputBox label={'Start Date'} name="startDate" form={{formState,errors,watch}}>
          <Input placeholder={'2022-12-01'} type="text" name="startDate" register={register({ required: true, validate: (value) =>{ if(!moment(value).isValid()) return 'Invalid Format'}})} ></Input>
        </NormalInputBox>
        <NormalInputBox label={'End Date'} name="endDate" form={{formState,errors,watch}}>
          <Input placeholder={'2022-12-01'} type="text" name="endDate" register={register({validate: (value) =>{ if(watch('endDate') && !moment(value).isValid()) return 'Invalid Format'}})} ></Input>
        </NormalInputBox>

        {message && <Box>
          {<MessageBar position={'relative'} width={'100%'} type={message.type ==='err'?'danger':'success'} message={message.msg} onClose={() => {setMessage({msg:'',type:''}); }}></MessageBar>}
        </Box>}
        {formState.isValid !== null && !globalLoading && <Box>
          <Button onClick={()=>history.goBack()} >Back</Button>
          <Button onClick={()=>handleSave(getValues)} disabled={!formState.dirty || !formState.isValid || (Object.keys(errors).length >= 1)}>Save</Button>
        </Box>}
    </Container>;
};

export default AddEditSubscription;
