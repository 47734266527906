import * as API from './api';

export interface SaveSubscriptionResponse {
  type: string; // LTEUploadSIM
  serial: string;
  startDate: string; //date formatted
  endDate: string | null;
  price: number; //monthly price in yen
  description: string;
  createdAt: number; //range Key
  userID:string;
}

export interface SaveSubscriptionRequest {
      serial: string; //secondary index;
      type: string;
      startDate: string; //date formatted
      endDate?: string | null;
      price: number; //monthly price in yen
      description?: string;
}

export const saveSubscription = async(body:SaveSubscriptionRequest): Promise<SaveSubscriptionResponse[]> => {
    if(body.endDate === '') delete body.endDate;
    const result:SaveSubscriptionResponse[] =  await API.invoke({
      lambdaName: `saveSubscription`,
      body
    });
    return result;
}

export const getSubscriptions = async(cognitoId:string): Promise<SaveSubscriptionResponse[]> => {
  const { subscriptions}: { subscriptions:SaveSubscriptionResponse[]} =  await API.invoke({
    lambdaName: `getSubscriptions`,
    body:{userID:cognitoId}
  });
  return subscriptions;
}


