import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { CheckMarkIcon, StarIcon, CloseBorderIcon } from '../svg';

const Label = styled.div`
  font-family: Rawline;
  font-size: 14px;
  font-weight: 500;
  color: hsl(207, 5%, 57%);
  margin-bottom:8px;
`;

const Container = styled.div`
  margin-top: 28px;
`;

const StateBox = styled.div`
  height: 45px;
  border-radius: 3px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
      display: inline-flex;
  align-items:center;
  min-width:45px;
  justify-content:center;
  svg{
    margin:0;
    fill:#fff;
    width: 45px;
  }
`;

const InputRow = styled.div<{color:string; }>`
  height: 45px;
  position: relative;
  input{
    height: 45px;
    border: solid 1px ${({color}) => color};
    border-radius: 3px;
    border-right: 0px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    padding:0 15px;
    min-width: 540px;
  }
  display: inline-flex;
  align-items: center;
  ${StateBox}{
    background-color: ${({color}) => color};
  }
  ${({color}) =>
    color === 'hsl(120, 1%, 85%)' ? css`
    input{
      border: solid 1px ${color};
      border-radius: 3px;
    }
    ${StateBox}{
        display: none;
      }
    ` :null
  }
`;

const Message = styled.div`
  font-family: Rawline;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  color: hsl(0, 0%, 100%);
  padding-right:12px;
`;


export interface NormalInputBoxProps {
  label?:string;
  className?:string;
  valid?:boolean;
  errors?:any;
  touch?:boolean;
  form:{
    formState:any;
    errors:any;
    watch:any;
  };
  name?:string;
}

const NormalInputBox: React.FC<NormalInputBoxProps> = ({label, name, children, className, form}) => {
    const {formState, errors:errorsState, watch} = form
    let color = 'hsl(120, 1%, 85%)';
    const touch = formState.touched.includes(name)
    const errors = errorsState[`${name}`]
    if(!touch){
      color = 'hsl(120, 1%, 85%)';
    } else if(errors) {
      color = errors.type === 'required' ? 'hsl(205, 59%, 71%)' :'hsl(0, 79%, 70%)';
    } else if(!watch(name)){
      color = 'hsl(120, 1%, 85%)';
    }else{
      color = 'hsl(120, 35%, 72%)';
    }
    
    return <Container className={className}>
      <Label>{label}</Label>
      <InputRow color={color}>
        {children}
        {color !== 'hsl(120, 1%, 85%)' &&
        <StateBox>
          {color === 'hsl(120, 35%, 72%)' && <CheckMarkIcon></CheckMarkIcon>}
          {errors != null && color === 'hsl(205, 59%, 71%)' &&
          <Fragment>
            <StarIcon></StarIcon>
            <Message>Required Field</Message>
          </Fragment>}
          {errors != null && color !== 'hsl(205, 59%, 71%)' &&
          <Fragment>
            <CloseBorderIcon></CloseBorderIcon>
            <Message>{errors.message}</Message>
          </Fragment>}
        </StateBox>}
      </InputRow>
    </Container>;
};

export default NormalInputBox;
