import React from 'react';
import styled from 'styled-components';
import { ReactComponentLike } from 'prop-types';

const Th = styled.th<{width:string;align: React.ThHTMLAttributes<HTMLTableHeaderCellElement>['align'];}>`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.35px;
  color: hsl(210, 2%, 51%);
  border-bottom: solid 1px hsl(205,15%,85%);
  text-align:${({align})=>align};
`;

const Container = styled.table`
  width:100%;
  border-spacing: 0px;
  & tr {
    height:49px;
    &:not(:last-child) td:not(:first-child):not(:nth-child(2)){
      border-bottom: solid 1px hsla(0, 0%, 74%,0.35);
    }
  }
  & tr:nth-child(2) {
      height:59px;
    }
  & td {
    height: inherit;
    position: relative;
  }
  & ::-webkit-scrollbar {
    display: none;
  }
`;


interface TableProps {
  ths: Ith[];
}

interface Ith {
  component:ReactComponentLike;
  align:  React.ThHTMLAttributes<HTMLTableHeaderCellElement>['align'];
  width:string;
}
const Table: React.FC<TableProps> = ({ths, children} ) => {
    return <Container>
    <tbody>
      <tr>
        {ths.map((v, i) => <Th key={i} align={v.align} width={v.width}>{<v.component></v.component>}</Th>)}
      </tr>
      {children}
    </tbody>
  </Container>;
};

export default Table;
