import { API } from 'aws-amplify'



export async function invoke<T>({lambdaName, body, }: {
  lambdaName: string;
  body?: {[param:string]: any}
}): Promise<T> {

  let {statusCode, data, ...rest} = await API.post(lambdaName, '', {
    body: {
      body:  JSON.stringify(body)
    }
  });
  if(statusCode >= 400){
    return Promise.reject({statusCode, data, ...rest});
  }
  return Promise.resolve(data);
}
