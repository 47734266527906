import styled, { css } from 'styled-components';

export const baseFont = css`
  font-family: Monorale, Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", Roboto, YuGothic, "游ゴシック", Meiryo, "メイリオ", sans-serif;
`;

export const secondFont = css`
  font-family: Lato, Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", Roboto, YuGothic, "游ゴシック", Meiryo, "メイリオ", sans-serif;
`;

export const Row = styled.div<{padding?:string; top?: string; height?: string; position?:string; width?: string; display?: string; align?: string, justify?: string, margin?: string}>`
  display: ${({display}) => (display ? display :'flex')};
  display: flex;
  flex-direction: row;
  align-items: ${props => (props.align || 'center')};
  justify-content:  ${props => (props.justify || 'unset')};
  margin:${({margin}) => (margin ? margin :null)};
  padding:${({padding}) => (padding ? padding :null)};
  width:${({width}) => (width ? width :null)};
  position:${({position}) => (position ? position :'relative')};
  height:${({height}) => (height ? height :null)};
  top:${({top}) => (top ? top :null)};
`;

export const Column = styled.div<{margin?:string; width?:string; align?: string; flex?: string; justify?: string; overflowY?:string;}>`
  overflow-y: ${props => (props.overflowY || 'unset')};
  display: flex;
  flex-direction: column;
  flex: ${props => (props.flex || 'unset')};
  justify-content:  ${props => (props.justify || 'unset')};
  width:${({width}) => (width ? width :null)};
  align-items: ${({align}) => (align ? align :null)};
  margin:${({margin}) => (margin ? margin :null)};
`;

export const RowCss = css`
  position:relative;
  display: flex;
  flex-direction: row;
  align-items:center;
`;

export const ColumnCss = css`
  display: flex;
  flex-direction: column;
`;

export const resetButtonStyles = css`
  background-color: transparent;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  user-select: none;
`;

export const MessageBox = styled.div`
position: relative;
margin:0px 47px 0px 47px;
`;

export const MessageBoxWithMargin = styled.div`
position: relative;
margin:37px 47px 0px 50px;
`;

export const WideContainer = css`
  position:relative;
  margin-left:47px;
  margin-right:47px;
  max-width: 1304px;
  padding-bottom: 130px;
`;

export const FeatureDescription = css`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.79;
  letter-spacing: normal;
  color: hsl(207, 5%, 57%);
  padding-top: 50px;
  padding-bottom: 40px;
  max-width: 685px;
`;

export const MenuTitle = css`
  font-size: 20px;
  font-weight: 500;
  color: hsl(208, 8%, 39%);
  margin-bottom:16px;
`;

export type SelectType = 'primary' | 'toolbar';

export const copyStringToClipboard = (str:any) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position =  'absolute';
  el.style.left =  '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};


export function formatBytes(bytes:number, decimals:number) {
  if(bytes === 0) { return  {size:0, format:'bytes',sizeFormat:'0 bytes'} }
  const k = 1000,
      dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ['bytes', 'kb', 'mb', 'gb', 'tb', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  return {size, format:sizes[i],sizeFormat:size + ' ' + sizes[i]} 
}

export const Loading = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 3px solid hsl(0, 0%, 100%);
  border-top-color:hsl(195, 89%, 82%);
  animation:loading 1.2s linear infinite;
  @keyframes loading{
    from { transform:rotate(0deg); }
    to   { transform:rotate(360deg); }
  }
`;


export const LoadingTable = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: solid 6px hsl(205, 77%, 64%);
  border-top-color:hsl(195, 89%, 82%);
  animation:loading 1.2s linear infinite;
  @keyframes loading{
    0%{transform:rotate(0deg)}
    100%{transform:rotate(360deg)}
  }
`;




