import * as API from './api';
  
export interface CreateUserResponse {
  
}

export interface CreateUserRequest {
      username: string; 
      email: string;
      password: string;
}

export const createUser = async(body:CreateUserRequest): Promise<CreateUserResponse> => {
    const result:CreateUserResponse =  await API.invoke({
      lambdaName: `createUser`,
      body
    });
    return result;
}


