import * as React from 'react';
import styled, { css } from 'styled-components';
import { resetButtonStyles, Loading, baseFont } from '../tools/common';

const LoadingBox = styled.div`
  width: 35px;
  height: inherit;
  background: hsl(195, 73%, 67%);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const NormalButton = styled.button<{backgroundColor?:string; margin?:string; loadingButton?:boolean; text?:boolean; buttonType?:ButtonType; color?:string; fontWeight?:string; fontSize?:string; bgColor?:string; border?:string; borderRadius?:string; colorType?:string; width?:string;iconRight?:string;}>`
  ${resetButtonStyles}
  ${baseFont}
  height: 40px;
  display:flex;
  align-items: center;
  flex-direction:row;
  justify-content: flex-end;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  color: hsl(0, 0%, 100%);
  border-radius: 3px;
  background: linear-gradient(108deg, hsl(200, 78%, 69%), hsl(207, 80%, 64%) 100%);
  &:hover{
    background: ${(props) => props.loadingButton ? 'hsl(195, 79%, 71%)':'hsl(207, 88%, 60%)'};
  }
  &:active{
    background: hsl(195, 79%, 71%);
  }
  &:disabled{
    color: hsl(0, 0%, 100%);
    background:  linear-gradient(97deg, hsla(200, 78%, 69%,0.6), hsla(207, 80%, 64%,0.6))
  }
  background: ${(props) => props.loadingButton && 'hsl(195, 79%, 71%)'};

  ${LoadingBox}{
    background: hsl(195, 73%, 67%);
  }

  ${props => props.text ? css`
  background:none;
  color: hsl(200, 78%, 69%);
  &:hover,&:active,&:disabled{
    background: none;
  }
  ` :null
  }

${props => props.colorType === 'danger' ? css`
  background: linear-gradient(290deg, hsl(0, 54%, 61%) 100%, hsl(0, 71%, 67%) 7%);
    &:hover{
      background: ${props.loadingButton ? 'hsl(5, 76%, 69%)':'hsl(0, 88%, 66%)'};
    }
    &:active{
      background: hsl(5, 76%, 69%);
    }
    &:disabled{
      color: hsl(0, 0%, 100%);
      background:  linear-gradient(290deg, hsla(0, 54%, 61%,0.6) 100%, hsla(0, 71%, 67%,0.6) 7%);
    }
    background: ${props.loadingButton && 'hsl(5, 76%, 69%)'};
    ${LoadingBox}{
      background: hsl(5, 64%, 63%);
    }
    ` :null
    }

${props => props.colorType === 'secondary' ? css`
    border-radius: 3px;
    background: hsl(0, 0%, 95%);
    color: hsl(0, 0%, 55%);
    &:hover{
      color: hsl(0, 1%, 45%);
      background: ${props.loadingButton ? 'hsl(204, 18%, 78%)':'hsl(206, 11%, 87%)'};
    }
    &:active{
      background: hsl(204, 18%, 78%);
      color: hsl(201, 7%, 39%);
    }
    &:disabled{
      background: hsl(0, 0%, 98%);
      color: hsl(0, 0%, 78%);
      opacity: 1;
    }

    ${LoadingBox}{
      background: hsl(203, 19%, 73%);
    }
    background: ${props.loadingButton && 'hsl(204, 18%, 78%)'};
    color: ${props.loadingButton && 'hsl(0, 0%, 100%)'};
    ${LoadingBox}{
      background: hsl(203, 19%, 73%);
    }
    ` :null
    }
  ${props => props.buttonType === 'round' ? css`
    border: 2px solid hsl(207, 71%, 70%);
    border-radius: 20px;
    background:none;
    color: hsl(207, 71%, 70%);
    ` :null
    }
  ${props => props.color ? css`
    color: ${props.color};
    ` :null
  }
  ${props => props.fontWeight ? css`
    font-weight: ${props.fontWeight};
    ` :null
  }
  ${props => props.fontSize ? css`
    font-size: ${props.fontSize};
    ` :null
  }
  ${props => props.bgColor ? css`
    background: ${props.bgColor};
    ` :null
  }
  ${props => props.border ? css`
    border: ${props.border};
    ` :null
  }
  ${props => props.borderRadius ? css`
    border-radius: ${props.borderRadius};
    ` :null
  }

  ${props => props.width ? css`
    width: ${props.width};
  `: null}
  }
  
  ${props => props.margin ? css`
    margin: ${props.margin};
    ` :null
  }

  ${({backgroundColor}) => backgroundColor ? css`
    background-color: ${backgroundColor};
      ` :null
  }

  svg{
    width:18px;
    height:18px;
    fill: hsla(0, 100%, 100%);
    margin: ${props => props.iconRight ? '0 0 0 10px': '0 10px 0 0'};
  }
`;

const ChildrenWrapper = styled.div<{isLoading?:boolean; padding?:string}>`
  padding: ${props =>props.isLoading ? '0 10px 0 20px' : '0 20px'};
  white-space: nowrap;
  margin: 0 auto;
  display: flex;
  align-items: center;
  ${props => props.padding ? 'padding:' + props.padding : null};
`;

type ButtonType = 'square'| 'round';

interface ButtonProps  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?:boolean;
  buttonType?:ButtonType;
  color?:string;
  fontWeight?:string;
  fontSize?:string;
  bgColor?:string;
  padding?:string;
  border?:string;
  borderRadius?:string;
  colorType?:string;
  loadingButton?:boolean;
  width?:string;
  iconRight?:string
  margin?:string;
  backgroundColor?:string;
}

const Button: React.FC<ButtonProps> = ({children,...props} ) => {
    return <NormalButton type={props.type ? props.type : 'button'} {...props}>
          <ChildrenWrapper isLoading={props.loadingButton} padding={props.padding}>
            {children}
          </ChildrenWrapper>
          {props.loadingButton && <LoadingBox><Loading></Loading></LoadingBox>}
    </NormalButton>;
};

export default Button;