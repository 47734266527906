import * as React from 'react';
import styled from 'styled-components';
import { EyeIcon, CopyIcon } from '../svg';
import { Row, copyStringToClipboard } from '../tools/common';
import { useState } from 'react';
export const DInput = styled.input`
  display:flex;
  flex:1;
  outline: none;
  border: none;
  position: relative;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  border-radius: 3px;
  color: #8d8d8d;
  ::placeholder {
    color: hsla(0, 0%, 46%, 0.5);
    font-style: italic;
  }
  :invalid {
    border-bottom:1px solid hsl(0, 32%, 60%);
  }
`;

const IconBox = styled.div`
  flex: 1;
  display: inline-flex;
  svg{
    margin-left:8px;
  }
`;

const EyeIconBox = styled.div`
  position:absolute;
  right:20px;
  display: inline-flex;
`;

const RowRelative = styled(Row)`
  position: relative;
`;

interface myProps {register?:any;}
type Props = React.InputHTMLAttributes<HTMLInputElement> & myProps;
const Input: React.FC<Props> =({register,name,...props}, ref) => {
  const [toggle, setToggle] = useState(true);
  if(props.type === 'password') {
    const type = toggle ? 'password' :'text';
    return <RowRelative>
      <DInput {...props} type={type} value={props.value} name={name} ref={register}></DInput>
      {props.disabled && <IconBox>
        <CopyIcon onClick={() => copyStringToClipboard(props.value)}></CopyIcon>
      </IconBox>}
      <EyeIconBox>
        <EyeIcon onClick={() => {setToggle((v) => !v); }}></EyeIcon>
      </EyeIconBox>
    </RowRelative>;
  } else {
    return <DInput {...props} name={name} ref={register}></DInput>;
  }
};

export default  React.forwardRef(Input);
