import env from '../lib/config';
const {
  REGION: region,
  COGNITO_USER_POOL_ID: userPoolId,
  COGNITO_IDENTITY_POOL_ID: identityPoolId,
  COGNITO_CLIENT_ID: userPoolWebClientId,
  ADMIN_API_VERSION,
  REALM
} = env;

const config = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId,

    // REQUIRED - Amazon Cognito Region
    region,

    // Amazon Cognito User Pool ID
    userPoolId,
    // Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId,

    mandatorySignIn: true,
  },

  API: {
    endpoints: [
      {
        name: 'getSubscriptions',
        endpoint: `https://lambda.ap-northeast-1.amazonaws.com/2015-03-31/functions/admin-${REALM}-${ADMIN_API_VERSION}-get-subscriptions/invocations`,
        service: 'lambda',
        region: 'ap-northeast-1'
      },
      {
        name: 'saveSubscription',
        endpoint: `https://lambda.ap-northeast-1.amazonaws.com/2015-03-31/functions/admin-${REALM}-${ADMIN_API_VERSION}-save-subscription/invocations`,
        service: 'lambda',
        region: 'ap-northeast-1'
      },
      {
        name: 'createUser',
        endpoint: `https://lambda.ap-northeast-1.amazonaws.com/2015-03-31/functions/admin-${REALM}-${ADMIN_API_VERSION}-create-user/invocations`,
        service: 'lambda',
        region: 'ap-northeast-1'
      },
    ]
  }
};

export default config;
