import React from 'react';
import styled, { css } from 'styled-components';
import { BeAwareIcon, WarningTriangleIcon, CloseIcon, SuccessIcon } from '../svg';
import { RowCss, Row } from '../tools/common';
const Container = styled.div<{type:barType; isHide:boolean; width:string; position:string; }>`
    max-width:1304px;
    margin-bottom:20px;
    ${RowCss}
    justify-content:space-between;
    min-height: 50px;
    padding:0 14px;
    position: absolute;
    bottom: 55px;
    left:0px;
    border-radius: 3px;
    & > svg{
      width: 14px;
      height: 14px;
      stroke:#fff;
      padding: 0;
    }
    ${({isHide}) => isHide ? css`display: none;` :null}
    ${({width}) => width ? css`width: ${width};` :null}
    ${({position}) => position ? css`position: ${position};bottom:0px;` :null}
    ${({type}) => {
      if(type === 'success') {
        return css`background-color: hsl(136, 50%, 57%);`;
      } else if(type === 'danger') {
        return css`background-color: hsl(0, 32%, 60%);`;
      } else if(type === 'warning') {
        return css`background-color: hsl(40, 71%, 60%);`;
      } else {
        return css`background-color: hsl(205, 77%, 64%);`;
      }
    }}
`;

const NoticeTxt = styled.span`
  font-family: Rawline;
  font-weight: 500;
  color: hsl(0, 0%, 100%);
  margin-left:15px;
  text-shadow: 0 2px 4px hsla(120, 41%, 43%, 0.65);
  font-size: 14px;
  color: hsl(0, 0%, 100%);
`;

const RightTxt = styled.span`
  cursor: pointer;
  font-family: Rawline;
  font-size: 14px;
  font-weight: 600;
  color: hsl(0, 0%, 100%);
`;

type barType = 'success'|'primary'|'danger'|'warning';
const MessageBar: React.FC<{rightTxt?:string; position?:string, width?:string; height?:string; message?:string, type?:barType, onClose?:() => void, onClick?:() => void}> = ({rightTxt= '', type = 'primary', message= '', width= '', height= '', onClose, position= '', onClick, children}) => {
    const renderIcon = () => {
      if(type === 'success'  ) {
        return <SuccessIcon></SuccessIcon>;
        } else if(type === 'danger') {
          return <WarningTriangleIcon></WarningTriangleIcon>;
        } else if(type === 'warning') {
          return <BeAwareIcon></BeAwareIcon>;
        } else {
          return <BeAwareIcon></BeAwareIcon>;
        }
    };
    return <Container type={type} isHide={message.length === 0} width={width} position={position}>
      <Row>
        {renderIcon()}
        <NoticeTxt>{message}</NoticeTxt>
      </Row>
      {rightTxt ? <RightTxt onClick={onClick}>{rightTxt}</RightTxt> :<CloseIcon onClick={onClose}></CloseIcon>}
  </Container>;
};

export default MessageBar;
